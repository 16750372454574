import React from "react"
import SEO from "../../components/common/seo"
import { css } from "@emotion/core"

function marketingTerms() {
  return (
    <div>
      <SEO title="marketingTerms" />
      <main css={marketing__terms__wrap}>
        <h1>광고성 정보 수신 동의 (선택동의)</h1>
        <hr />
        <article>
          주식회사 플랜즈커피(이하 "회사")본인은 본 서비스에서 수집 및
          이용에동의한 개인정보(필수동의 및 선택동의 포함)를 활용하여, 귀사가
          본인에게전자적 전송매체를 통해 광고∙홍보∙프로모션∙이벤트 제공
          목적으로, 플랜즈커피 상품 또는 서비스에 대한 개인 맞춤형 광고∙정보를
          전송하는 것에 동의합니다.
        </article>
        <p>
          ※ 본 동의 내용은 거부할 수 있으며 거부에 따른 불이익은 없습니다.
          <br />※ 본 수신동의를 철회하고자 할 경우 02 6956 0232로 연락을 통해
          수신동의 철회요청을 할 수 있습니다.
        </p>
      </main>
    </div>
  )
}

const marketing__terms__wrap = css`
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media (min-width: 800px) {
    width: 760px;
  }
  word-break: keep-all;
  font-family: Spoqa Han Sans;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  h1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.48;
    text-align: left;
    color: #3e3e3e;
  }
  hr {
    margin-top: 28px;
    margin-bottom: 37px;
    border: 1px solid #707070;
  }
  article {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    color: #707070;
  }
  p {
    margin-top: 37px;
    margin-left: 50px;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    color: #707070;
  }
`

export default marketingTerms
